import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../../../../src/common/components/Layout'
import { SeoData } from '../../../../src/common/types/Seo'
import { MenuPrincipal } from '../../constants/menu'
import { useSelectedMenu } from '../../helpers/customHooks/useSelectedMenu'
import styles from './index.module.scss'

const history = [
  {
    year: '2014',
    text: 'Consolidación empresa líder del sector en las Islas Canarias',
    img: `https://${process.env.RODI_AWS_BUCKET}/estaticas/empresa/2014-elpaso2000.png`,
  },
  {
    year: '2017',
    text: 'Nueva imagen corporativa El Paso 2000',
    img: `https://${process.env.RODI_AWS_BUCKET}/estaticas/empresa/2017-elpaso2000.png`,
  },
  {
    year: '2019',
    text: 'Contamos con Emma Falcón, campeona de Europa de rallies',
    img: `https://${process.env.RODI_AWS_BUCKET}/estaticas/empresa/2019-elpaso2000.png`,
  },
  {
    year: '2019',
    text:
      'Donativo a Asociación de Productores de Artenara por los incendios en Gran Canaria',
    img: `https://${process.env.RODI_AWS_BUCKET}/estaticas/empresa/2019-artenara-elpaso2000.png`,
  },
  {
    year: '2020',
    text: 'Lanzamos nuestra nueva plataforma digital www.elpaso2000.com',
    img: `https://${process.env.RODI_AWS_BUCKET}/estaticas/empresa/2020-elpaso2000.png`,
  },
]

interface Props {
  data: {
    seoData: SeoData
  }
}
const empresa = ({ data, ...props }: Props) => {
  useSelectedMenu(MenuPrincipal.HOME)
  return (
    <Layout selector={false} seoData={data.seoData}>
      <div className={styles.empresa}>
        <div className={styles.empresa_title_cont}>
          <h1 className={styles.empresa_title}>
            El Paso 2000 ven por el precio, vuelve por el servicio
          </h1>

          <p className={styles.empresa_text}>
            La cadena de centros de automoción El Paso 2000 nace en Canarias en
            1990 fruto de la experiencia en la distribución y montaje de
            neumáticos para automóviles, producto estrella que nos ha hecho
            famosos a lo largo de estos años.
          </p>
          <p className={styles.empresa_text}>
            Gracias al duro trabajo basado en la dedicación humana y técnica del
            equipo de más de 150 profesionales distribuidos en nuestros 15
            talleres por las islas, nos hemos convertido en la cadena de
            talleres líderes en Canarias en mecánica rápida, servicio posventa y
            mantenimiento de neumáticos para turismos, guaguas y camiones.
          </p>
        </div>

        <div className={styles.timeline}>
          {history.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  index % 2 === 0
                    ? `${styles.container} ${styles.left}`
                    : `${styles.container} ${styles.right}`
                }>
                <div
                  className={
                    index % 2 === 0
                      ? `${styles.content_left} `
                      : `${styles.content_right} `
                  }>
                  <span className={styles.date}>{item.year}</span>
                  <span className={styles.text}>{item.text}</span>
                </div>
                <div className={styles.yellowdot} />
                <div
                  className={
                    index % 2 === 0
                      ? `${styles.img_left} `
                      : `${styles.img_right} `
                  }>
                  <img src={item.img} alt="empresa" title="" />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}
export default empresa

export const pageQuery = graphql`
  query empresaAuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
